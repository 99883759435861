@import "./import.scss";

.title {
  font-size: 150%;
  @include md {
    font-size: 200%;
  }
  &.right {
    text-align: right;
  }
}
.bold {
  font-weight: bold;
}
.line {
  height: 1px;
  background-color: $black;
  margin-top: 24px;
  margin-bottom: 24px;
}

.App {
  text-align: center;
}
.orange {
  color: $orange;
}

.button {
  all: unset;
  background: $orange;
  border: none;
  outline: none;
  font-size: 15px;
  height: 34px;
  border-radius: 17px;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: bold;
  text-align: center;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    //lighten color a bit
    background: lighten($orange, 10%);
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.section {
  .inner {
    padding: 24px;
    margin: 0 auto;
    max-width: 1200px;
  }
  @include md {
    padding: 48px;
  }
  &.dark {
    background-color: #222;
    color: $white;
    .line {
      background-color: #f2f2f2;
    }
  }
  &.grey {
    background-color: #f2f2f2;
  }
}

.flex1 {
  flex: 1;
}
