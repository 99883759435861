@import "../../import.scss";

.Home {
  min-width: 0;
}
.welcomeBlock {
  display: flex;
  flex-direction: column;
  padding: 50px;
  background-color: white;
  min-width: 0;

  text-align: center;
  align-items: center;
  background-color: $orange;
  justify-content: center;
  .wbInner {
    max-width: 1210px;
    margin: 0 auto;
    width: 100%;
    align-items: center;
    justify-content: center;
    .left {
      width: 33%;
    }
    .right {
      @include md{
        flex: 1;

      }
    }
    font-size: 90%;
    display: flex;
    flex-direction: row-reverse;
    text-align: justify;
  }

  .title {
    font-weight: bold;
    margin-bottom: 24px;
    font-size: 200%;
  }
}
.partnerLogoGroup {
  min-width: 0;
  display: flex;
  align-items: center;
  background-color: #222;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 50px;
  padding-right: 50px;
  @include md {
    padding-top: 14px;
    padding-bottom: 14px;
    flex-direction: column;
  }
}
.partnerLogos {
  flex: 1;
  width: 100%;
  display: flex;
  @include md {
    min-height: 8vh;
    justify-content: space-around;
    flex-direction: row;
  }
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
  max-width: 1248px;
  .partnerLogo {
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
    max-width: 60px;
    margin-bottom: 20px;
    text-align: center;
    flex: 1;
    @include md {
      height: 30px;
      max-width: none;
      width: auto;
      justify-content: space-around;
    }
    img {
      object-fit: contain;
      width: 100%;
    }
  }
}
.icons {
  background-color: #222;
  color: white;
  padding: 50px;
  text-align: center;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  font-family: "Bakbak One", sans-serif;
  .iconSubText {
    padding-top: 8px;
    font-size: 80%;
    color: #777;

    font-family: "Open Sans", sans-serif;
  }
  @include md {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;

    .icon {
      margin-bottom: 0;

      margin-right: 12px;
      margin-left: 12px;
    }
  }
  .icon {
    margin-bottom: 24px;
  }
}
.iconImage {
  color: $orange;
  font-size: 250%;
}
.fullBox {
  overflow: hidden;
  min-width: 0;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 5px;
  text-align: center;
  align-items: center;
  justify-content: center;
  @include md {
    height: 80vh;
  }
  border-bottom: 2px solid $orange;
  position: relative;
  .scrollDownButton {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 150%;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: bounce-7;
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    color: white;
  }
  #myVideo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: black;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    object-fit: cover;
  }
  @include md {
    flex-direction: row;
    padding-top: 5px;
  }

  .left {
    flex: 1;
    min-width: 0;
    display: flex;
    align-items: center;
    max-width: 624px;
    width: 100%;
    min-width: 0;

    .bigText {
      font-family: "Bakbak One";
      font-size: 200%;
      color: white;
      margin-left: 20px;
      margin-right: 20px;
      @include md {
        text-align: left;
        max-width: 404px;
      }
      .smallHeaderText {
        margin-top: 30px;
        font-size: 40%;
        font-family: "Open Sans";
      }
      .orange {
        color: $orange;
        font-weight: bold;
      }
    }
  }
  .right {
    max-width: 624px;
    flex: 1;
    min-width: 0;
  }
  .homeButtons {
    font-size: 50%;
    gap: 20px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include md {
      justify-content: flex-start;
    }
  }
  .textLink {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.vrHolder {
  height: 300px;
  min-width: 0;
  overflow: hidden;
  @include md {
    height: 500px;
  }
}

.vrCanvas {
  min-width: 0;
  pointer-events: none;
  @include md {
    touch-action: auto;
    pointer-events: all;
  }
  > div {
    min-width: 0;
  }
  canvas {
    min-width: 0;
    @include md {
      touch-action: auto;
      pointer-events: all;
    }
  }
}

.fullImageBox {
  .moreButtonWrap {
    position: absolute;
    bottom: 150px;
    left: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    .moreButton {
      background-color: $orange;
      width: 120px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 8px;
      color: #222;
      font-size: 80%;
    }
  }
  height: 100vh;
  &.half {
    height: 50vh;
  }

  &.third {
    height: 66vh;
  }
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: flex-end;
  position: relative;
  &.left {
    align-items: flex-start;
    .boxText {
      text-align: right;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      border-bottom-right-radius: 24px;
      border-top-right-radius: 8px;
    }
  }
  .scrollDownButton {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 150%;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: bounce-7;
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    color: white;
  }
  .boxText {
    position: sticky;
    top: 80px;
    .largeBoxText {
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: "Bakbak One";
      font-size: 300%;
    }
    width: 80%;
    margin-top: 150px;
    background-color: #222222dd;
    margin-bottom: 20px;

    @include sm {
      top: 130px;
      width: 40%;
    }
    @include md {
      top: 80px;
    }

    color: white;
    padding: 24px;
    padding-left: 24px;
    font-weight: bold;
    border-bottom-left-radius: 24px;
    border-top-left-radius: 8px;
    .subBoxText {
      margin-top: 12px;
      font-weight: 400;
    }
    .readMoreLink {
      background-color: $orange;
      color: inherit;
      padding: 8px;
      padding-left: 12px;
      padding-right: 12px;
      font-size: 80%;
      border-radius: 20px;
      border-top-right-radius: 12px;
      border-bottom-left-radius: 12px;
      transition: border-radius 0.3s, background-color 0.3s;
      text-decoration: none;

      &:hover {
        border-top-right-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: darken($color: $orange, $amount: 3%);
      }
    }
  }
}
.hiddenAnchor {
  text-decoration: none;
  font-size: 0;
  height: 0;
  max-height: 0;
  line-height: 0;
  padding: 0;
  margin: 0;
}

.teamArea {
  background-color: #222;
  color: white;

  padding-top: 80px;
  @include sm {
    padding-top: 120px;
  }
  @include md {
    padding-top: 80px;
  }

  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    text-align: center;
    font-family: "Bakbak One";
    font-weight: 400;
    font-size: 200%;
    margin-bottom: 48px;
    color: $orange;
  }
  .sub {
    color: white;
    font-size: 80%;
    text-align: center;
    margin-left: 40px;
    margin-right: 40px;
    max-width: 500px;
  }
  .members {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 50px;
    margin-bottom: 48px;
    @include md {
      flex-direction: row;
    }
    .member {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 4px;
      img {
        width: 200px;
        height: 200px;
        object-fit: cover;
        border-radius: 12px;
        margin-bottom: 10px;
      }
      .memberName {
      }
      .memberRole {
        color: $orange;
        font-weight: bold;
        font-size: 80%;
      }
    }
  }
}

@keyframes bounce-7 {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  57% {
    transform: translateY(-7px);
  }
  64% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
