@import "../../import.scss";

.ContactForm {
  display: flex;
  gap: 30px;
  padding-bottom: 0;
  flex-direction: column;
  justify-content: center;
  max-width: 1248px;
  min-width: 0px;
  width: 100%;
  margin: 0 auto;
  @include md {
    padding-top: 80px;
  }
  @include lg {
    flex-direction: row;
  }
  .contactTitle {
    margin-left: 20px;
    font-weight: bold;
    font-size: 200%;
    @include md {
      white-space: pre-wrap;
    }
    .subContactTitle {
      font-size: 80%;
      font-weight: 400;
      @include md {
        white-space: pre-wrap;
      }
    }
  }
  .contactContent {
    margin-right: 20px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    .flex {
      flex-direction: column;
      @include lg {
        flex-direction: row;
      }
    }
  }
  .contactInput {
    flex: 1;
    font-family: "Quicksand", sans-serif;
    outline: none;
    border: none;
    border: 1px solid #bbb;
    padding: 12px;
    border-radius: 4px;
  }
  .contactButton {
    margin-top: 10px;
    background-color: #444;
    color: white;
    font-size: 120%;
    border: none;
    font-weight: bold;
    border-radius: 8px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: $orange;
    }
  }
}
.emailText {
  align-items: center;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  .emailLink {
    margin-top: 15px;
    color: inherit;
    font-size: 180%;
    font-weight: 100;
    text-decoration: none;
  }
}
