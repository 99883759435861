@import "../../import.scss";

.Header {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 80%;
  min-width: 0;
  position: fixed;
  top: 0;
  z-index: 1;
  background: #222222dd;
  color: white;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  a {
    text-decoration: none;
    color: inherit;
  }
  .demoButton {
    color: #222;
  }
  .inner {
    display: flex;
    max-width: 1248px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  @include sm {
    .inner {
      display: block;
      .mobileNavButton {
        display: none;
      }
    }
  }
  .mobileNavButton {
    &:hover {
      color: $orange;
    }
  }
  @include md {
    .inner {
      display: flex;
    }
  }
  .logo {
    min-width: 0;
    display: flex;
    align-items: center;
    gap: 12px;

    .logoImg {
      height: 30px;
      object-fit: contain;
    }
    .logoType {
      height: 25px;
    }
    @include sm {
      justify-content: center;
      .logoImg {
        height: 40px;
        object-fit: contain;
      }
      .logoType {
        height: 30px;
      }
    }
  }
  .break {
    min-width: 0;
    flex: 1;
    height: 12px;
  }
  .nav {
    display: none;
    min-width: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 18px;
    cursor: pointer;
    @include sm {
      display: flex;
      flex-direction: row;
    }
    .navItem {
      &:hover {
        color: $orange;
      }

      &:active {
        color: $orange;
      }
    }
  }
}
.demoButton {
  min-width: 0;
  height: 30px;
  font-size: 80%;
  background-color: $orange;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  color: #222;
  font-weight: bold;
  font-family: "Open Sans";

  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  transition: border-radius 0.3s, background-color 0.3s;
  text-decoration: none;

  cursor: pointer;
  &:hover {
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: darken($color: $orange, $amount: 3%);
  }
}

.mobileNav {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000dd;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  touch-action: none;
  backdrop-filter: saturate(180%) blur(5px);
  white-space: nowrap;
  max-width: 0;
  min-width: 0;
  box-shadow: -3px 0px 4px 0px #00000033;
  .navItem {
    color: white;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    font-size: 100%;
    border-bottom: 1px solid #dddddd55;
    margin-left: 12px;
    text-decoration: none;
    &:last-child {
      border-bottom: none;
    }
  }
  @include sm {
    display: none;
  }
}

.underConstruction{
  text-align: center;
  min-height: 40px;
  background-color: #ff0000;

  font-weight: bold;
  margin-top: 20px;
  position: fixed;
  bottom:0;
  left:0;
  right:0;
  z-index: 10;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 12px;
  color:white;
  >a{
    text-decoration: underline;
    color:white;
  }
}