@import "../../import.scss";

.ProjectPage {
  .title {
    font-weight: bold;
    font-size: 150%;
    margin-top: 12px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    .backbutton {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        color: $orange;
      }
    }
  }
  .sub {
    margin-bottom: 12px;
    color: #444;
  }
  .hero {
    display: flex;
    flex-direction: column;
    img {
      flex: 1;
    }
  }
  .text {
    margin-top: 24px;
    text-align: justify;
  }
}

.orangeBar {
  height: 20px;
  background: $orange;
  margin-top: 50px;
  margin-bottom: 50px;
}
