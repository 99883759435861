/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../public/fonts/open-sans-v34-latin-regular.eot");
  src: local(""),
    url("../public/fonts/open-sans-v34-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/open-sans-v34-latin-regular.woff2") format("woff2"),
    url("../public/fonts/open-sans-v34-latin-regular.woff") format("woff"),
    url("../public/fonts/open-sans-v34-latin-regular.ttf") format("truetype"),
    url("../public/fonts/open-sans-v34-latin-regular.svg#OpenSans")
      format("svg");
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../public/fonts/open-sans-v34-latin-600.eot");
  src: local(""),
    url("../public/fonts/open-sans-v34-latin-600.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/open-sans-v34-latin-600.woff2") format("woff2"),
    url("../public/fonts/open-sans-v34-latin-600.woff") format("woff"),
    url("../public/fonts/open-sans-v34-latin-600.ttf") format("truetype"),
    url("../public/fonts/open-sans-v34-latin-600.svg#OpenSans") format("svg");
}

/* work-sans-regular - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../public/fonts/work-sans-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/fonts/work-sans-v18-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../public/fonts/work-sans-v18-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/fonts/work-sans-v18-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../public/fonts/work-sans-v18-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/work-sans-v18-latin-regular.svg#WorkSans")
      format("svg"); /* Legacy iOS */
}
/* work-sans-600 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../public/fonts/work-sans-v18-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/fonts/work-sans-v18-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../public/fonts/work-sans-v18-latin-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/fonts/work-sans-v18-latin-600.woff") format("woff"),
    /* Modern Browsers */ url("../public/fonts/work-sans-v18-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/work-sans-v18-latin-600.svg#WorkSans") format("svg"); /* Legacy iOS */
}

/* bakbak-one-regular - latin */
@font-face {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  src: url("../public/fonts/bakbak-one-v6-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/fonts/bakbak-one-v6-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../public/fonts/bakbak-one-v6-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../public/fonts/bakbak-one-v6-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../public/fonts/bakbak-one-v6-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/fonts/bakbak-one-v6-latin-regular.svg#BakbakOne") format("svg"); /* Legacy iOS */
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-scroll-behavior: smooth;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  min-width: 0;
}
body {
  color: #333;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-scroll-behavior: smooth;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  min-width: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  min-width: 0;
}
.App {
  min-width: 0;
}
