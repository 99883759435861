@import "../../import.scss";

.Footer {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: $orange;
  width: 100%;
  color: #222;
  .addressWrapper {
    margin-bottom: 48px;
    font-size: 0;
    padding-top: 24px;
    max-width: 900px;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 48px;
    @include lg {
      display: flex;
      .address {
        margin-bottom: 0;
      }
    }
    .googleMap {
      width: 100%;
      height: 250px;
    }
    .address {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 48px;

      font-size: 16px;
    }
    .frame {
      flex: 1;
    }
  }
  .footerLinks {
    font-size: 80%;

    margin-top: 20px;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
