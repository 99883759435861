@import "../../import.scss";

.Layout {
  flex-direction: column;
  display: flex;

  min-width: 0;
  > .inner {
    display: flex;
    flex-direction: column;

    margin: 0 auto;

    min-width: 0;
    margin-top: 80px;
    > .inner {
      max-width: 1248px;
    }
  }
}
