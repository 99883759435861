@import "../../import.scss";
.PortfolioPage {
  .subTitle {
    text-align: center;
    margin-top: 48px;
    margin-bottom: 24px;
    font-weight: bold;
  }
}
.textZone {
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 90%;
  background-color: #eee;
  border-radius: 5px;
  padding: 12px;
  margin-top: 24px;
  margin-right: 12px;
  margin-left: 12px;
  max-width: 1100px;
  margin-top: 30px;
  margin-bottom: 0;

  @include lg {
    margin: 30px auto;
    margin-bottom: 0;
  }

  .title {
    font-family: "Bakbak One";
    font-size: 200%;
    margin-bottom: 12px;
  }
}
.pastProjects {
  max-width: 1100px;
  margin: 0 auto;
  min-height: 300px;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 20px;
  @include sm {
    display: flex;
    gap: 12px;
  }
  .project {
    position: relative;
    display: flex;
    align-items: flex-end;
    flex: 1;
    background-position: center center;
    background-size: cover;
    width: 100%;

    min-height: 300px;
    margin-bottom: 12px;
    cursor: pointer;
    @include sm {
      min-width: 330px;
    }
    &:hover {
      .projectInfo {
        opacity: 0;
      }
    }
    color: inherit;
    text-decoration: none;
    .projectInfo {
      background-color: $orange;
      opacity: 0.9;
      font-family: "Bakbak One";
      color: white;
      flex: 1;
      backdrop-filter: blur(20px);
      transition: opacity 0.5s;
      display: flex;
      flex-direction: column;
      .projectName {
        font-size: 150%;
        margin-bottom: 4px;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 8px;
      }
      .projectText {
        font-size: 80%;
        padding: 8px;
      }
    }
  }
}
