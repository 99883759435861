@import "../../import.scss";

.Industrie {
}
.videoWrapper {
  display: flex;
  overflow: hidden;
  max-height: 800px;
  position: relative;

  .videoOverlay {
    @include md {
      display: block;
    }
    display: none;
    position: absolute;
    top: 20%;
    right: 0;
    z-index: 0;
    background-color: #000000aa;
    color: white;
    padding: 12px;
    width: 400px;
    font-size: 90%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    .overlayTitle {
      font-size: 150%;
      margin-bottom: 24px;
    }
  }
  video {
    width: 100%;
    object-fit: cover;
  }
}
.mobileVideoInfos {
  padding: 24px;
  background-color: #222;
  color: white;
  border-bottom: 1px solid white;
  .overlayTitle {
    font-size: 150%;
    margin-bottom: 24px;
  }
  @include md {
    display: none;
  }
}
.textImageZone {
  flex-direction: column;
  margin-bottom: 48px;
  @include md {
    flex-direction: row;
  }
  display: flex;
  gap: 30px;
  align-items: center;
  &.reverse {
    flex-direction: column-reverse;
    @include md {
      flex-direction: row;
    }
  }
  .title {
    font-weight: bold;
    font-size: 100%;
    margin-bottom: 24px;
  }
  .image {
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      overflow: hidden;
      object-fit: contain;
      width: 80%;
    }
  }
  .text {
    flex: 1;
  }
}
